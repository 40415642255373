import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-webgpu';
import { ref } from 'vue';

const isTfReady = ref(false);
let initializationPromise = null;

export const initializeTensorFlow = async () => {
    if (!initializationPromise) {
        initializationPromise = (async () => {
            try {
                await tf.ready();
                console.log('TensorFlow.js version:', tf.version);
                console.log('Initial backend:', tf.getBackend());

                const preferredBackends = ['cpu'];//'webgpu', 'webgl', 
                for (const backend of preferredBackends) {
                    if (tf.findBackend(backend)) {
                        try {
                            console.log(`Attempting to set ${backend} backend...`);
                            await tf.setBackend(backend);
                            console.log(`Successfully set ${backend} backend.`);
                            break;
                        } catch (e) {
                            console.warn(`Failed to set ${backend} backend:`, e);
                        }
                    } else {
                        console.log(`${backend} backend is not available.`);
                    }
                }

                console.log('Final backend:', tf.getBackend());

                if (!tf.getBackend()) {
                    throw new Error('Failed to initialize any TensorFlow backend');
                }

                isTfReady.value = true;
                console.log('TensorFlow.js initialization complete');
            } catch (error) {
                console.error('Error initializing TensorFlow.js:', error);
                isTfReady.value = false;
            }
        })();
    }
    return initializationPromise;
};

export const getTF = () => {
    if (!isTfReady.value) {
        throw new Error('TensorFlow not initialized. Await initializeTensorFlow() before using getTF().');
    }
    return tf;
};

export { isTfReady };