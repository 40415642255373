<template>
  <div>
    <div v-if="!isOnline" class="bg-red-500 rounded text-white p-2 text-center mb-4">
      You are currently offline. Please check your internet connection.
    </div>
    <div
      v-if="showConnectionStatus"
      class="connection-status-fixed"
      :class="connectionStatus"
    >
      {{ connectionStatus.charAt(0).toUpperCase() + connectionStatus.slice(1) }}
    </div>
    <div v-if="!hideControlButtons" class="flex gap-2 mb-4">
      <div class="flex gap-2">
        <button
          @click="robots.length > 0 && !isTraining && sendCommand('disperse')"
          :class="{
            'bg-green-500 hover:bg-green-700 cursor-pointer':
              robots.length > 0 && !isTraining && !isCommandLooping,
            'bg-green-300 cursor-not-allowed':
              robots.length === 0 || isTraining || isCommandLooping,
          }"
          class="text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          :disabled="isTraining || isCommandLooping"
        >
          Disperse
        </button>
        <button
          @click="robots.length > 0 && !isTraining && sendCommand('gather')"
          :class="{
            'bg-yellow-500 hover:bg-yellow-700 cursor-pointer':
              robots.length > 0 && !isTraining && !isCommandLooping,
            'bg-yellow-300 cursor-not-allowed':
              robots.length === 0 || isTraining || isCommandLooping,
          }"
          class="text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          :disabled="isTraining || isCommandLooping"
        >
          Gather
        </button>
        <button
          @click="robots.length > 0 && !isTraining && sendCommand('stop')"
          :class="{
            'bg-red-500 hover:bg-red-700 cursor-pointer':
              robots.length > 0 && !isTraining,
            'bg-red-300 cursor-not-allowed': robots.length === 0 || isTraining,
          }"
          class="text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          :disabled="isTraining"
        >
          Stop All
        </button>
        <button
          @click="robots.length > 0 && !isTraining && sendCommand('capture-image')"
          :class="{
            'bg-purple-500 hover:bg-purple-700 cursor-pointer':
              robots.length > 0 && !isTraining,
            'bg-purple-300 cursor-not-allowed': robots.length === 0 || isTraining,
          }"
          class="text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          :disabled="isTraining"
        >
          <i class="fas fa-camera"></i> Capture Images
        </button>
      </div>
    </div>

    <!-- Test Mode Indicator and Open Simulator Button -->
    <div class="flex items-center justify-start p-4">
      <label for="toggleTestMode" class="flex items-center cursor-pointer">
        <div class="relative">
          <input
            id="toggleTestMode"
            type="checkbox"
            class="hidden"
            v-model="isTestMode"
            @change="setSimulationMode(isTestMode)"
          />
          <div class="toggle-path bg-gray-200 w-9 h-5 rounded-full shadow-inner"></div>
          <div
            class="toggle-circle absolute top-0.5 left-0.5 w-4 h-4 rounded-full transition bg-white shadow inset-y-0"
          ></div>
        </div>
        <div class="ml-3 text-gray-700 font-medium">Simulation Mode</div>
      </label>
    </div>

    <!-- Robot Grid -->
    <div
      class="mt-6 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4"
    >
      <div
        v-for="robot in filteredRobots"
        :key="robot.id"
        class="robot bg-white shadow-lg rounded-lg overflow-hidden p-3"
        :class="{ 'selected-robot': selectedRobot === robot.id }"
        @click="!isTraining && selectRobot(robot.id)"
      >
        <div class="bg-gray-800 p-2 text-white font-mono text-sm robot-title">
          Robot {{ robot.id }}
        </div>
        <div class="canvas-overlay-container relative">
          <img
            :src="robot.image_data || DEFAULT_ROBOT_IMAGE"
            alt="Robot Image"
            class="robot-image"
          />
          <canvas
            :id="'canvas-' + robot.id"
            width="240"
            height="240"
            class="robot-canvas absolute top-0 left-0 w-full h-full"
          ></canvas>
        </div>
        <div class="robot-details p-4 font-mono text-black">
          <div>Distance travelled: {{ robot.totalDistance }}</div>
          <div>Distance to nearest object: {{ robot.distance }}</div>
        </div>
        <div class="control-buttons">
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'turn-left:1000')"
            class="control-button"
            aria-label="Move Left"
            :disabled="isTraining"
          >
            <i class="fas fa-arrow-left"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'turn-right:1000')"
            class="control-button"
            aria-label="Move Right"
            :disabled="isTraining"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'move-forward:1000')"
            class="control-button"
            aria-label="Move Forward"
            :disabled="isTraining"
          >
            <i class="fas fa-arrow-up"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'move-backward:1000')"
            class="control-button"
            aria-label="Move Backward"
            :disabled="isTraining"
          >
            <i class="fas fa-arrow-down"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'stop')"
            class="control-button"
            aria-label="Stop"
            :disabled="isTraining"
          >
            <i class="fas fa-stop"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'capture-image')"
            class="control-button"
            aria-label="Capture Image"
            :disabled="isTraining"
          >
            <i class="fas fa-camera"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import * as tf from "@tensorflow/tfjs";
// Vue and related imports
import {
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
  watch,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";

// External library imports
import { Client, Message } from "paho-mqtt";
import axios from "axios";

// Custom classes and services
import ppoConfig from "@/rl/PPOConfig";
import SharedBuffer from "@/rl/SharedBuffer";
import MultiRobotPPO from "@/rl/MultiRobotPPO";
import MultiRobotEnvironment from "@/rl/MultiRobotEnvironment";
import ConvergenceSpeedTracker from "@/rl/ConvergenceSpeedTracker";
import AuthService, { handleGlobalError } from "@/services/AuthService";
import { trainExperiment, updateExperiment } from "@/services/ExperimentService";
import * as ExperimentService from "@/services/ExperimentService";
import { initializeTensorFlow, isTfReady } from "@/services/TensorFlowService";

// Component setup
const emit = defineEmits([
  "training-started",
  "training-stopped",
  "experiment-updated",
  "experiment-added",
  "training-error",
  "log-added",
  "log-updated",
  "show-notification",
]);

const router = useRouter();

// Constants
const INITIAL_RETRY_DELAY = 1000; // 1 second
const MAX_RETRY_ATTEMPTS = 5;
const CACHE_EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
const DEFAULT_ROBOT_IMAGE = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVR42mP8/5+hnoEIwDiqkL4KAcT9GO0U4BxoAAAAAElFTkSuQmCC`;
const STATUS_UPDATE_INTERVAL = 5000; // 5 seconds, adjust as needed
const MAX_LOG_ENTRIES = 100;
const HEARTBEAT_INTERVAL = 5000; // 5 seconds
const INACTIVE_THRESHOLD = 15000; // 15 seconds

// Reactive state
const isTestMode = ref(localStorage.getItem("simulationMode") === "true");
const isGathering = ref(false);
const isDispersing = ref(false);
const isOnline = ref(navigator.onLine);
const isConnected = ref(false);
const isConnecting = ref(false);
const isCommandLooping = ref(false);
const userScrolled = ref(false);
const disconnectedRobots = ref(new Set());

// Refs for UI elements and data
const connectionStatus = ref("disconnected");
const showConnectionStatus = ref(false);
const mainTopic = ref(isTestMode.value ? "robotswarm-sim" : "robotswarm");
const client = ref(null);
const logs = ref([]);
const logContainer = ref(null);
const logId = ref(0);
const reconnectInterval = ref(null);
const isTraining = ref(false);
const isTrainingOrRetraining = ref(false);
const isSimulatorOpen = ref(false);
const isSimulatorLoaded = ref(false);
const simulatorOrigin = "https://sim.terracrawler.com"; // Update this to match your simulator's URL
const simulatorWindow = ref(null);
const isSimulatorUnloading = ref(false);
const backendError = ref(false);
const userRobots = ref([]);

// Machine Learning related refs
const policyModel = ref(null);
const valueModel = ref(null);
const agent = ref(null);
const robotPPOs = ref({});
const sharedBuffer = ref(new SharedBuffer(10000)); // Adjust size as needed

const env = ref(new MultiRobotEnvironment([]));

// Reactive objects
const robots = reactive([]);
const robotIntervals = reactive({});

// Computed properties
const currentUser = computed(() => AuthService.getCurrentUser().value);

// Non-reactive variables
let retryAttempts = 0;
let commandInterval = null;
let statusTimeout = null;
let inactiveCheckInterval;
const robotResponseTracker = new Map();

// Non-reactive objects
const tracker = new ConvergenceSpeedTracker();

// Caches
const ownershipCache = new Map();
const lastStatusUpdateTime = new Map();

watch(connectionStatus, () => {
  showConnectionStatus.value = true;
  clearTimeout(statusTimeout);
  statusTimeout = setTimeout(() => {
    showConnectionStatus.value = false;
  }, 5000);
});

const createPPOForRobot = async (robotId, trainingAim) => {
  const env = new MultiRobotEnvironment(robotId);
  env.setTrainingAim(trainingAim);
  const ppo = new MultiRobotPPO(env, ppoConfig, sharedBuffer.value);
  await ppo.initialize();
  return ppo;
};

const setupHeartbeat = () => {
  setInterval(() => {
    robots.forEach((robot) => {
      sendControlCommand(robot.id, "ping");
    });
  }, HEARTBEAT_INTERVAL);
};

const handleHeartbeat = async (robotIds) => {
  console.log(`Handling heartbeat for robots:`, robotIds);

  for (const robotId of robotIds) {
    updateHeartbeat(robotId);

    const existingRobot = robots.find(
      (robot) => robot.id === robotId || robot.mac_address === robotId
    );

    if (!existingRobot) {
      console.log("New robot detected:", robotId);
      await addRobot(robotId);
    } else {
      console.log("Heartbeat updated for existing robot:", robotId);
      getRobotStatus(robotId);
    }
  }

  console.log("After heartbeat, robots:", robots);
};

function updateHeartbeat(robotId) {
  const robot = robots.find((r) => r.id === robotId || r.mac_address === robotId);
  if (robot) {
    robot.lastHeartbeat = Date.now();
  }
}

const checkInactiveRobots = () => {
  const now = Date.now();
  robots.forEach((robot) => {
    if (now - robot.lastHeartbeat > INACTIVE_THRESHOLD) {
      console.log(`Robot ${robot.id} inactive. Removing.`);
      handleRobotDisconnect(robot.id);
    }
  });
};

const addRobot = async (robotId) => {
  const ownershipData = await checkRobotOwnership(robotId, isTestMode.value);
  if (ownershipData.owned) {
    const existingRobotIndex = robots.findIndex(
      (r) => r.id === robotId || r.mac_address === robotId
    );

    if (existingRobotIndex !== -1) {
      console.log(`Robot ${robotId} reconnected`);
      handleRobotReconnect(robotId);
    } else {
      const newRobot = {
        id: ownershipData.mac_address,
        robot_id: ownershipData.robot_id,
        mac_address: ownershipData.mac_address,
        position: {
          x: 0,
          y: 0,
        },
        status: "unknown",
        image_data: DEFAULT_ROBOT_IMAGE,
        totalDistance: 0,
        distance: 0,
        objects: [],
        isSimulated: ownershipData.is_simulated,
        swarmIds: ownershipData.swarm_ids || [],
        lastAction: null,
        consecutiveSameActions: 0,
        i: 0,
        lastHeartbeat: Date.now(),
      };

      robots.push(newRobot);
      console.log(`Robot added: ${robotId}`);
      console.log("Current robots:", robots);

      if (client.value && client.value.isConnected()) {
        client.value.subscribe(`${mainTopic.value}/response/robot/${robotId}`);
        addLog(
          "subscribe",
          "Subscribe",
          `${mainTopic.value}/response/robot/${robotId}`,
          ""
        );
      } else {
        console.warn("MQTT client not connected, couldn't subscribe to robot topic");
      }
      // Initialize the robot's status
      getRobotStatus(robotId);

      // If we're in a training session, we might need to update the environment
      if (isTraining.value || isTrainingOrRetraining.value) {
        if (!robotPPOs.value[robotId]) {
          const trainingAim = currentExperiment.value
            ? currentExperiment.value.trainingAim
            : "explore";
          robotPPOs.value[robotId] = await createPPOForRobot(robotId, trainingAim);
          console.log(
            `Created PPO instance for robot ${robotId} with training aim: ${trainingAim}`
          );
        }
      }

      // If this robot belongs to the current experiment's swarm, we might need to update the experiment
      if (
        currentExperiment.value &&
        newRobot.swarmIds.includes(currentExperiment.value.swarm_id)
      ) {
        console.log(`Robot ${robotId} added to current experiment swarm`);
        // You might want to update the experiment or notify the user here
      }

      // Initialize the robot's status
      getRobotStatus(robotId);

      // Emit an event to notify parent components if necessary
      emit("robotsUpdated", robots);
    }
  } else {
    console.log(`Robot ${robotId} is not owned by the current user. Ignoring.`);
  }
};

const connectWithRetry = async (retryCount = MAX_RETRY_ATTEMPTS) => {
  if (isConnecting.value) {
    console.log("Connection attempt already in progress. Skipping.");
    return;
  }

  if (retryCount <= 0) {
    console.error("Max retries reached. Unable to connect to MQTT broker.");
    connectionStatus.value = "disconnected";
    isConnecting.value = false;
    router.push("/login");
    return;
  }

  isConnecting.value = true;
  connectionStatus.value = "connecting";

  try {
    await connectMQTT();
    isConnecting.value = false;
  } catch (error) {
    console.error("Connection attempt failed:", error);
    connectionStatus.value = "disconnected";

    if (error === "No auth token") {
      console.error("No authentication token found. Logging out...");
      await AuthService.logout();
      router.push("/login");
      return; // Exit the function to prevent further retries
    }

    const delay = INITIAL_RETRY_DELAY * Math.pow(2, MAX_RETRY_ATTEMPTS - retryCount);
    console.log(
      `Retrying in ${delay / 1000} seconds... (${retryCount - 1} attempts left)`
    );
    setTimeout(() => {
      isConnecting.value = false;
      connectWithRetry(retryCount - 1);
    }, delay);
  }
};

const saveModels = async (experiment, combinedPolicyModel, combinedValueModel) => {
  console.log("Saving models for experiment:", experiment);
  if (!isTfReady.value) {
    console.error("TensorFlow.js is not ready. Cannot save models.");
    return;
  }

  try {
    // Convert model weights to JSON serializable format
    const policyWeights = combinedPolicyModel.getWeights().map((w) => w.arraySync());
    const valueWeights = combinedValueModel.getWeights().map((w) => w.arraySync());

    // Create request payload
    const payload = {
      experiment_id: experiment._id,
      policy_weights: policyWeights,
      value_weights: valueWeights,
    };

    await axios.post("/api/models", payload);
    console.log("Averaged models saved to MongoDB successfully");
  } catch (error) {
    console.error("Error saving averaged models:", error);
  }
};

function formatString(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, "_")
    .replace(/[^a-z0-9_]/g, "");
}

const loadModels = async () => {
  try {
    policyModel.value = await tf.loadLayersModel("indexeddb://policy-model");
    valueModel.value = await tf.loadLayersModel("indexeddb://value-model");
    console.log("Models loaded successfully");
    await initializeAgent();
  } catch (error) {
    console.warn("Error loading models:", error);
  }
};

const initializeAgent = async () => {
  if (!isTfReady.value) {
    console.log("TensorFlow.js is not ready. Waiting for initialization...");
    await initializeTensorFlow();
  }

  if (!isTfReady.value) {
    console.error("Failed to initialize TensorFlow.js. Cannot initialize agent.");
    return false;
  }

  console.log("TensorFlow.js is ready. Initializing agent...");
  const numRobots = currentExperiment.value?.numRobots || 0;
  console.log(`Initializing agent for ${numRobots} robots...`);

  try {
    console.log("Creating MultiRobotPPO instance...");
    env.value.validateEnvironment();
    return true;
  } catch (error) {
    console.error("Failed to initialize agent:", error);
    console.error("Error details:", error.stack);
    throw new Error(`Failed to initialize agent: ${error.message}`);
  }
};

const setSimulationMode = (mode) => {
  isTestMode.value = mode;
  mainTopic.value = mode ? "robotswarm-sim" : "robotswarm";
  localStorage.setItem("simulationMode", mode);
  console.log(
    `Switched to ${mode ? "simulation" : "production"} mode. Main topic is now '${
      mainTopic.value
    }'.`
  );
};

const sendSwarmCommand = (swarmId, command) => {
  if (!client.value || !client.value.isConnected()) {
    console.error("MQTT client is not connected");
    return;
  }

  const topic = `${mainTopic.value}/command/swarm/${swarmId}`;
  const payload = JSON.stringify({ command });

  const message = new Message(payload);
  message.destinationName = topic;
  message.qos = 1;

  client.value.send(message);
  console.log(`Sent swarm command '${command}' to swarm ${swarmId}`);
  addLog("send", `Swarm command '${command}'`, topic, payload);
};

const sendExperimentCommand = (experimentId, action) => {
  if (!client.value || !client.value.isConnected()) {
    console.error("MQTT client is not connected");
    return;
  }

  const topic = `${mainTopic.value}/command/experiment`;
  const payload = JSON.stringify({
    experiment_id: experimentId,
    action: action,
  });

  const message = new Message(payload);
  message.destinationName = topic;
  message.qos = 1;

  client.value.send(message);
  console.log(`Sent experiment ${action} command for experiment ${experimentId}`);
  addLog("send", `Experiment ${action} command`, topic, payload);
};

const handleExperimentAdded = async () => {
  showExperimentForm.value = false;
};

const closeAllSimulatorWindows = () => {
  if (simulatorWindow.value && !simulatorWindow.value.closed) {
    simulatorWindow.value.close();
    console.log("Simulator window closed");
    simulatorWindow.value = null;
    isSimulatorOpen.value = false;
  }
};

const handleSimulatorMessage = (event) => {
  if (event.origin !== simulatorOrigin) return;

  console.log("Received message from simulator:", event.data);

  if (event.data === "simulator_loaded") {
    console.log("Simulator loaded successfully");
    isSimulatorLoaded.value = true;
    sendCommand("ping");
  } else if (event.data === "robots_added") {
    console.log("Checking for connected robots after robots_added message");
  } else if (event.data === "simulator_unloaded") {
    console.log("Simulator unloaded");
    handleSimulatorUnload();
  }
};

const openSimulator = async (experimentId = null) => {
  console.log("openSimulator called with experimentId:", experimentId);

  if (isSimulatorOpen.value && simulatorWindow.value && !simulatorWindow.value.closed) {
    console.log("Simulator window is already open. Sending new experiment data.");
    sendExperimentDataToSimulator(experimentId);
    simulatorWindow.value.focus();
    return;
  }

  const token = AuthService.getToken();
  if (!token) {
    console.error("No authentication token found");
    throw new Error("No authentication token found");
  }

  const currentUser = await AuthService.getCurrentUser();
  if (!currentUser) {
    console.error("Failed to retrieve user data");
    throw new Error("Failed to retrieve user data");
  }

  const userIdentifier = currentUser._id || currentUser.username;
  const windowName = `terracrawlerSimulator_${userIdentifier}`;
  let url = `${simulatorOrigin}?token=${token}`;
  if (experimentId) {
    url += `&experiment_id=${experimentId}`;
  }

  console.log("Opening new simulator window");
  simulatorWindow.value = window.open(url, windowName, "width=800,height=600,status");

  if (simulatorWindow.value) {
    console.log("New simulator window opened");
    isSimulatorOpen.value = true;

    // Set up message listener for simulator events
    window.addEventListener("message", handleSimulatorMessage);

    // Wait for the simulator to signal it's loaded
    try {
      await waitForSimulatorLoad();
      console.log("Simulator loaded successfully");
    } catch (loadError) {
      console.error("Error waiting for simulator to load:", loadError);
      isSimulatorOpen.value = false;
      window.removeEventListener("message", handleSimulatorMessage);
      throw loadError;
    }

    robots.splice(0, robots.length);
    console.log("All robots removed before opening simulator");
  } else {
    console.error(
      "Failed to open simulator window. Please check your popup blocker settings."
    );
    emit("show-notification", "Failed to open simulator window");
    throw new Error("Failed to open simulator window");
  }
};

const closeSimulator = async () => {
  if (isSimulatorUnloading.value) return;
  isSimulatorUnloading.value = true;

  if (simulatorWindow.value && !simulatorWindow.value.closed) {
    simulatorWindow.value.close();
  }
  await handleSimulatorUnload();

  isSimulatorUnloading.value = false;
};

const handleSimulatorUnload = async () => {
  if (isSimulatorUnloading.value) return;
  isSimulatorUnloading.value = true;

  console.log("Handling simulator unload");
  isSimulatorOpen.value = false;
  isSimulatorLoaded.value = false;

  simulatorWindow.value = null;
  robots.splice(0, robots.length);

  window.removeEventListener("message", handleSimulatorMessage);
  if (currentExperiment.value) {
    emit("training-stopped", currentExperiment.value);
    emit("experiment-updated", currentExperiment.value);
  }
  console.log("Simulator unload handled");
  isSimulatorUnloading.value = false;
};

const waitForSimulatorLoad = () => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error("Simulator load timeout"));
    }, 30000); // 30 seconds timeout

    const checkLoaded = () => {
      if (isSimulatorLoaded.value) {
        clearTimeout(timeout);
        resolve();
      } else if (!isSimulatorOpen.value) {
        clearTimeout(timeout);
        reject(new Error("Simulator window was closed"));
      } else {
        setTimeout(checkLoaded, 100);
      }
    };
    checkLoaded();
  });
};

const sendExperimentDataToSimulator = async (experimentId) => {
  if (!isSimulatorOpen.value || !simulatorWindow.value) {
    console.log("Simulator window is not open. Opening a new window...");
    openSimulator(experimentId);
    return;
  }

  if (!client.value || !client.value.isConnected()) {
    console.error("MQTT client is not connected. Cannot send experiment data.");
    return;
  }

  const topic = `${mainTopic.value}/simulator/experiment`;

  try {
    console.log("Fetching experiment details for ID:", experimentId);
    const experimentData = await ExperimentService.getExperimentDetails(experimentId);

    if (!experimentData) {
      throw new Error("Failed to fetch experiment data");
    }

    const payload = {
      experiment_id: experimentId,
      experiment: experimentData,
    };

    const message = new Message(JSON.stringify(payload));
    message.destinationName = topic;
    message.qos = 1;

    client.value.send(message);
    console.log("Sent experiment data to simulator:", payload);

    // Update currentExperiment.value with the fetched data
    currentExperiment.value = experimentData;

    simulatorWindow.value.postMessage(JSON.stringify(experimentData), simulatorOrigin);
  } catch (error) {
    console.error("Error fetching or sending experiment data:", error);
  }
};

const startExperimentTraining = async (experiment, isRetrain = false) => {
  try {
    console.log(
      "RobotSwarmController: startExperimentTraining called",
      experiment,
      isRetrain
    );
    currentExperiment.value = { ...experiment };
    console.log("Current experiment set:", currentExperiment.value);

    setSimulationMode(experiment.isSimulation);

    // Update training aim for all existing robot PPOs
    for (const robotId in robotPPOs.value) {
      robotPPOs.value[robotId].env.setTrainingAim(experiment.trainingAim);
      if (isRetrain || experiment.isSimulation) {
        robotPPOs.value[robotId].env.episode = 1;
      }
    }

    if (experiment.isSimulation) {
      try {
        await openSimulator(experiment._id);
        console.log("Simulator opened and loaded successfully");
      } catch (error) {
        console.error("Failed to open or load simulator:", error);
        throw new Error(`Failed to open simulator: ${error.message}`);
      }
    }

    isTraining.value = true;
    isTrainingOrRetraining.value = true;

    if (isRetrain) {
      experiment.currentEpisode = 1;
      experiment.progress = 0;
    }

    console.log("Initializing agent...");
    const swarmRobots = robots.filter((robot) =>
      robot.swarmIds.includes(experiment.swarm_id)
    );
    for (const robot of swarmRobots) {
      if (!robotPPOs.value[robot.id]) {
        robotPPOs.value[robot.id] = await createPPOForRobot(
          robot.id,
          experiment.trainingAim
        );
      } else {
        robotPPOs.value[robot.id].env.setTrainingAim(experiment.trainingAim);
      }
    }

    console.warn("Agent initialized successfully, starting training...");
    await runExperimentTraining(isRetrain);
  } catch (error) {
    console.error("Error in startExperimentTraining:", error);
    isTraining.value = false;
    isTrainingOrRetraining.value = false;
    emit("training-error", error.message);
    throw error;
  }
};

const updateEpisodeViaSimulator = (experimentId, currentEpisode) => {
  if (!isTraining.value) {
    console.error("Cannot update episode via simulator: not in training mode");
    return;
  }

  const topic = `${mainTopic.value}/simulator/episode`;
  const message = new Message(JSON.stringify({ experimentId, currentEpisode }));
  message.destinationName = topic;
  message.qos = 1;

  client.value.send(message);
  console.log("Sent episode update to simulator:", { experimentId, currentEpisode });

  // Update the currentExperiment and emit the update
  if (currentExperiment.value && currentExperiment.value._id === experimentId) {
    currentExperiment.value.currentEpisode = currentEpisode;
    emit("experiment-updated", { ...currentExperiment.value });
  }
};

const runExperimentTraining = async (isRetrain = false) => {
  console.log("RobotSwarmController: runExperimentTraining called", isRetrain);
  if (!currentExperiment.value) {
    console.error(
      "RobotSwarmController: No current experiment, exiting runExperimentTraining"
    );
    return;
  }

  const experimentId = currentExperiment.value._id;
  const { numEpisodes, numRobots, trainingAim, swarm_id } = currentExperiment.value;
  console.log("Experiment details:", {
    numEpisodes,
    numRobots,
    trainingAim,
    swarm_id,
  });

  const totalEpisodes = parseInt(numEpisodes, 10);
  console.log(`Starting training with ${totalEpisodes} episodes`);

  const updateProgress = async (episode, iteration, maxIterations, reward, accuracy) => {
    const progress = (episode - 1 + iteration / maxIterations) / totalEpisodes;
    try {
      const updatedExperiment = await updateExperiment(experimentId, {
        progress,
        currentEpisode: episode,
        reward,
        accuracy,
        status: "Running",
      });
      currentExperiment.value = { ...currentExperiment.value, ...updatedExperiment };
      emit("experiment-updated", currentExperiment.value);
    } catch (error) {
      console.error("Error updating experiment progress:", error);
    }
  };

  // Wait for all robots to connect
  const allRobotsConnected = await waitForRobots(numRobots, swarm_id);
  if (!allRobotsConnected) {
    console.error("Not all robots connected. Aborting training.");
    emit("show-notification", "Training aborted: Not all robots connected");
    return;
  }

  console.warn("😊📢 Running experiment training...");
  console.log(tf.memory().numBytes);
  backendError.value = false;

  // Get robots in the current swarm
  const swarmRobots = robots.filter((robot) => robot.swarmIds.includes(swarm_id));
  if (swarmRobots.length !== numRobots) {
    console.warn(
      `Expected ${numRobots} robots, but found ${swarmRobots.length} in the swarm`
    );
  }

  // Ensure all robots in the swarm have a PPO instance
  for (const robot of swarmRobots) {
    if (!robotPPOs.value[robot.id]) {
      robotPPOs.value[robot.id] = await createPPOForRobot(robot.id, trainingAim);
    } else {
      robotPPOs.value[robot.id].env.setTrainingAim(trainingAim);
    }
  }

  try {
    const episodeDuration = 10000; // 10 seconds
    const maxIterations = 100; // Assuming 100ms per iteration, this gives 30 seconds
    let allRobotsReachedAim = false;
    let episodeStartTime = null;
    let iteration = 0;
    let allRobotsConnected = false;

    for (let episode = 1; episode <= totalEpisodes; episode++) {
      if (
        !isTraining.value ||
        backendError.value ||
        !isSimulatorOpen.value ||
        !isSimulatorLoaded.value
      ) {
        console.error("Training stopped");

        console.log("Experiment ID:", experimentId);
        console.log("Episode:", episode);
        console.log("Total Episodes:", totalEpisodes);
        console.log("Iteration:", iteration);
        console.log("Duration since start time:", Date.now() - episodeStartTime);
        console.log("All robots reached aim:", allRobotsReachedAim);
        console.log("Is training:", isTraining.value);
        console.log("Is simulator open:", isSimulatorOpen.value);
        console.log("Is simulator loaded:", isSimulatorLoaded.value);
        console.log("Backend error:", backendError.value);

        break;
      }

      episodeStartTime = Date.now();
      allRobotsReachedAim = false;

      console.log(`Starting episode ${episode} of ${totalEpisodes}`);
      updateEpisodeViaSimulator(experimentId, episode);

      while (
        Date.now() - episodeStartTime < episodeDuration &&
        !allRobotsReachedAim &&
        isTraining.value &&
        isSimulatorOpen.value &&
        isSimulatorLoaded.value &&
        !backendError.value
      ) {
        console.log(
          `Episode ${episode}: Running iteration ${iteration} at ${
            Date.now() - episodeStartTime
          }ms`
        );

        console.log("Experiment ID:", experimentId);
        console.log("Episode:", episode);
        console.log("Total Episodes:", totalEpisodes);
        console.log("Iteration:", iteration);
        console.log("Duration since start time:", Date.now() - episodeStartTime);
        console.log("All robots reached aim:", allRobotsReachedAim);
        console.log("Is training:", isTraining.value);
        console.log("Is simulator open:", isSimulatorOpen.value);
        console.log("Is simulator loaded:", isSimulatorLoaded.value);
        console.log("Backend error:", backendError.value);

        allRobotsReachedAim = await runEpisodeIteration(swarmRobots, episode);
        const episodePerformance = calculateEpisodePerformance(swarmRobots);
        await updateProgress(
          episode,
          iteration,
          maxIterations,
          episodePerformance.reward,
          episodePerformance.accuracy
        );
        console.log(`Updated experiment for episode ${episode}`);

        iteration++;
        // Short delay to prevent overwhelming the system
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      if (!isTraining.value) {
        console.log("Training stopped during episode");
        break;
      }

      // Calculate overall reward and accuracy for this episode
      const episodePerformance = calculateEpisodePerformance(swarmRobots);
      console.log(
        `Episode ${episode} performance - Reward: ${
          episodePerformance.reward
        }, Convergence speed = ${tracker.getConvergenceSpeed()}, Accuracy: ${
          episodePerformance.accuracy
        }%`
      );

      // Update the experiment progress, reward, and accuracy
      try {
        const trainingData = {
          progress: episode / totalEpisodes,
          currentEpisode: episode,
          reward: episodePerformance.reward,
          accuracy: episodePerformance.accuracy,
          status: "Running",
        };
        const updatedExperiment = await trainExperiment(experimentId, trainingData);
        console.log(`Updated experiment for episode ${episode}`);

        // Update currentExperiment and emit the update
        currentExperiment.value = { ...currentExperiment.value, ...updatedExperiment };
        emit("experiment-updated", currentExperiment.value);
      } catch (error) {
        console.error("Error updating experiment:", error);
      }

      await cleanupTFMemory();
      console.log(`Completed episode ${episode} of ${totalEpisodes}`);
    }

    // After the loop completes, update the experiment status to 'Completed'
    if (currentExperiment.value) {
      const currentEpisode = currentExperiment.value.currentEpisode;
      const totalEpisodes = currentExperiment.value.numEpisodes;
      const isSimulation = currentExperiment.value.isSimulation;

      console.log(`Current episode: ${currentEpisode}, Total episodes: ${totalEpisodes}`);
      console.warn("😊📢 Training was completed.");

      if (
        currentEpisode >= totalEpisodes &&
        currentExperiment.value.status !== "Completed"
      ) {
        try {
          const bestPerformingPPO = await selectBestPerformingMultiRobotPPO(robotPPOs);

          if (bestPerformingPPO) {
            console.log("Best performing PPO:", bestPerformingPPO);
            // Function to safely save a model
            await bestPerformingPPO.saveModels();
          }
        } catch (error) {
          console.error("Error combining networks:", error);
        }

        console.log("RobotSwarmController: Experiment training completed");
        try {
          try {
            await stopExperimentTraining(currentExperiment.value);
          } catch (error) {
            emit("training-stopped", currentExperiment.value);
            console.error("Error stopping experiment:", error);
          }
        } catch (error) {
          console.error("Error updating experiment status to Completed:", error);
        }
      } else if (currentExperiment.value.status === "Stopped") {
        console.log("RobotSwarmController: Experiment was manually stopped");
        emit("training-stopped", currentExperiment.value);
      }

      // Close the simulator window if it's a simulation
      console.log("Closing simulator window after training completion");
      await closeSimulator();

      currentExperiment.value = null;
    }
  } catch (error) {
    console.error("Error during experiment training:", error);
    emit("training-stopped", currentExperiment.value);
    emit("show-notification", "Error occurred during training.");
  } finally {
    isTraining.value = false;
    isTrainingOrRetraining.value = false;
    if (currentExperiment.value) {
      currentExperiment.value = null;
    } else {
      console.log("RobotSwarmController: currentExperiment is null, training loop ended");
    }
  }
};

const calculateEpisodePerformance = (swarmRobots) => {
  let totalReward = 0;
  let totalAccuracy = 0;
  let validRobotCount = 0;

  for (const robot of swarmRobots) {
    const ppo = robotPPOs.value[robot.id];
    if (ppo) {
      totalReward += ppo.averageRewards;
      totalAccuracy += ppo.performanceScore;
      validRobotCount++;
    }
  }

  if (validRobotCount === 0) {
    console.error("No valid robot performance data available");
    return { reward: 0, accuracy: 0 };
  }

  const averageReward = totalReward / validRobotCount;
  const averageAccuracy = totalAccuracy / validRobotCount;

  return {
    reward: averageReward,
    accuracy: averageAccuracy,
  };
};

const runEpisodeIteration = async (swarmRobots, episode) => {
  let allRobotsReachedAim = true;

  for (const robot of swarmRobots) {
    try {
      console.log(`Processing Robot ${robot.id}`);
      const ppo = robotPPOs.value[robot.id];
      ppo.initializeBackend();
      ppo.env.numberOfSteps = 0;
      ppo.env.episode = episode;
      if (!ppo) {
        console.error(`No PPO instance found for robot ${robot.id}`);
        continue;
      }

      const robotState = ppo.env.getRobotState();
      console.log(`Robot ${robot.id} state:`, robotState);

      // Check if robotState is valid
      if (!Array.isArray(robotState) || robotState.length !== 33) {
        console.error(`Invalid robot state for ${robot.id}:`, robotState);
        continue; // Skip this iteration if the state is invalid
      }

      // Get the updated robot data
      const updatedRobotData = robots.find((r) => r.id === robot.id);
      if (updatedRobotData) {
        console.log(`Updated data for robot ${robot.id}:`, updatedRobotData);

        // Update the environment with the new robot data
        ppo.env.updateRobot(updatedRobotData);

        // Move action retrieval and command sending after the update
        let action;
        try {
          action = await ppo.getAction(robot.id, robotState);
        } catch (error) {
          console.error(`Error in getAction for robot ${robot.id}:`, error);
          continue; // Skip this iteration if getAction fails
        }
        console.log(`Action for robot ${robot.id}:`, action);

        // Ensure action is a valid integer between 0 and 4
        const validAction = Math.floor(Math.max(0, Math.min(4, action)));
        console.log(`Valid action for robot ${robot.id}:`, validAction);

        // Send the action to the robot or simulator
        const newStatus = await sendControlCommand(
          robot.id,
          actionToCommand(validAction),
          true
        );
        ppo.env.updateRobot(newStatus);

        // Step the environment and get the new state, reward, and done flag
        const [newState, reward, done] = ppo.env.step(validAction);
        tracker.addReward(reward);
        console.log(`Step result for robot ${robot.id}:`, { newState, reward, done });

        // Check if newState is valid before updating PPO
        if (Array.isArray(newState) && newState.length === 33) {
          // Update the PPO agent with the new information  
          await ppo.update(robotState, validAction, reward, newState, done);
        } else {
          console.error(`Invalid newState for ${robot.id}:`, newState);
        }

        if (!done) {
          allRobotsReachedAim = false;
        }
      } else {
        console.log(robot.id + " robot got disconnected during training.");
        return true;
      }
    } catch (error) {
      console.error(`Error processing robot ${robot.id}:`, error);
      console.error("Error stack:", error.stack);
      allRobotsReachedAim = false;
      backendError.value = true;
      tf.disposeVariables();
      break;
    }
  }

  return allRobotsReachedAim;
};

const waitForRobots = (expectedRobots, swarmId, timeout = 60000) => {
  return new Promise((resolve) => {
    const startTime = Date.now();
    const checkRobots = () => {
      sendCommand("ping");
      const connectedRobots = robots.filter((robot) => {
        console.log(
          `Checking robot: ${robot.id}, swarmIds: ${JSON.stringify(robot.swarmIds)}`
        );
        return robot.swarmIds && robot.swarmIds.includes(swarmId);
      });
      console.log(
        `Connected robots: ${connectedRobots.length}, Expected: ${expectedRobots}`
      );
      if (connectedRobots.length >= expectedRobots) {
        console.log(`All ${expectedRobots} robots connected successfully`);
        resolve(true);
      } else if (Date.now() - startTime > timeout) {
        console.warn(
          `Timeout waiting for robots. Connected: ${connectedRobots.length}, Expected: ${expectedRobots}`
        );
        resolve(false);
      } else {
        setTimeout(checkRobots, 1000); // Check every second
      }
    };
    checkRobots();
  });
};

const actionToCommand = (action) => {
  switch (action) {
    case 0:
      return "move-forward:50";
    case 1:
      return "move-backward:50";
    case 2:
      return "turn-left:50";
    case 3:
      return "turn-right:50";
    default:
      return "stop";
  }
};

const getAllLogs = () => {
  return displayedLogs.value;
};

function getRobotStatus(robotId) {
  if (typeof robotId === "object") {
    console.error(
      "getRobotStatus called with an object instead of a string ID:",
      robotId
    );
    return; // Exit if robotId is not a string
  }

  const currentTime = Date.now();
  const lastUpdateTime = lastStatusUpdateTime.get(robotId) || 0;

  // Check if enough time has passed since the last status update
  if (currentTime - lastUpdateTime < STATUS_UPDATE_INTERVAL) {
    console.log(
      `Skipping status request for robot ${robotId}: too soon since last update`
    );
    return;
  }

  const command = "status"; // This is the command your robot expects
  sendControlCommand(robotId, command);

  // Update the last status update time for this robot
  lastStatusUpdateTime.set(robotId, currentTime);
}

watch(isTestMode, async (newValue) => {
  console.log("Simulation Mode Changed:", newValue);
  localStorage.setItem("simulationMode", newValue);

  // Clear logs
  logs.value = [];

  // Disconnect existing robots and clear the array
  if (client.value && client.value.isConnected()) {
    robots.forEach((robot) => {
      handleRobotDisconnect(robot.id);
    });
    client.value.disconnect(); // Disconnect the current MQTT client
  }

  // Clear robots array
  robots.length = 0;
  await nextTick();
  console.log("All robots removed before opening simulator");

  // Update the main topic
  mainTopic.value = newValue ? "robotswarm-sim" : "robotswarm";
  console.log(
    `Switched to ${newValue ? "simulation" : "production"} mode. Main topic is now '${
      mainTopic.value
    }'.`
  );

  // Reconnect with the new settings
  connectWithRetry();

  // Additional logging for debugging
  if (!newValue) {
    console.log("Clearing robots");
  }
  console.log("Current Robots:", robots);
});

watch(
  () => isTestMode.value,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await stopAllProcesses();
    }
  }
);

watch(isOnline, (newVal) => {
  isOnline.value = newVal;
  if (!newVal) {
    console.error("Internet connection is offline.");
  }
});

function connectMQTT() {
  if (!isOnline.value) {
    console.error("Cannot connect to MQTT because the internet is offline.");
    return Promise.reject("Offline");
  }
  const token = AuthService.getToken();
  if (!token) {
    console.error("No authentication token found. Please log in.");
    return Promise.reject("No auth token");
  }
  console.log("MQTT Client starts...");
  client.value = new Client(
    "fb6d6dae8e9948d5b1af0453ac29ae30.s1.eu.hivemq.cloud",
    8884,
    "robot-swarm-master" + new Date().getTime()
  );
  client.value.onConnectionLost = onConnectionLost;
  client.value.onMessageArrived = onMessageArrived;

  const willMessage = new Message(JSON.stringify({ token }));
  willMessage.destinationName = `${mainTopic.value}/disconnect`;
  willMessage.qos = 1;
  willMessage.retained = false;

  return new Promise((resolve, reject) => {
    client.value.connect({
      useSSL: true,
      userName: "csiszi",
      password: "dycdY3-hobfix-rijvux",
      onSuccess: () => {
        onConnect();
        resolve();
      },
      onFailure: (responseObject) => {
        console.error("Connection failed. Error code:", responseObject.errorCode);
        console.error("Error message:", responseObject.errorMessage);
        console.error("Full response object:", responseObject);
        onFail(responseObject);
        reject(responseObject);
      },
      willMessage: willMessage,
    });
  });
}

function extractRobotIdFromTopic(topic) {
  const regex = /^([a-z-:\w]*)\/response\/robot\/([A-Za-z0-9:]+)/;
  const match = topic.match(regex);
  return match ? match[2] : null; // Returns the robot ID or null if no match is found
}

function escapeRegex(string) {
  // This function escapes special characters that have special meanings in regex
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const onMessageArrived = async (message) => {
  const topic = message.destinationName;
  const payloadString = message.payloadString;

  addLog("receive", "", topic, payloadString);

  // Check for disconnect message first
  if (
    topic === `${mainTopic.value}/heartbeat` &&
    payloadString.startsWith("Disconnect:")
  ) {
    const robotId = payloadString.substring(11).trim(); // Remove "Disconnect:" prefix and any leading/trailing whitespace
    console.log(`Received disconnect message for robot: ${robotId}`);
    handleRobotDisconnect(robotId);
    return; // Exit the function after handling the disconnect
  }

  // For other messages, try to parse as JSON
  let payload;
  try {
    payload = JSON.parse(payloadString);
  } catch (error) {
    console.error("Error parsing MQTT message:", error);
    console.log("Non-JSON message received:", payloadString);
    // Handle non-JSON messages here if needed
    return;
  }

  // Handle JSON messages
  const robotId = extractRobotIdFromTopic(topic);
  try {
    if (robotId && payload.command === "status") {
      const currentTime = Date.now();
      const lastUpdateTime = lastStatusUpdateTime.get(robotId) || 0;

      // Check if enough time has passed since the last status update
      if (lastUpdateTime !== 0 && currentTime - lastUpdateTime < 100) {
        console.log(
          `Skipping status update for robot ${robotId}: too soon since last update`
        );
        return;
      }

      updateRobotStatus(robotId, payload.result);

      // Update the last status update time when a status message is received
      lastStatusUpdateTime.set(robotId, Date.now());
    }

    if (topic === `${mainTopic.value}/logs`) {
      addLog("notice", "", topic, payload.result);
    } else {
      addLog("receive", "", topic, message.payloadString);
    }

    const regex = /^([a-z-:\w]*)\/response\/robot\/([A-Za-z0-9:]+)/;

    if (topic === `${mainTopic.value}/heartbeat`) {
      console.log("Raw heartbeat payload:", payload);
      try {
        let heartbeatData;
        if (typeof payload === "string") {
          heartbeatData = JSON.parse(payload);
        } else if (typeof payload === "object") {
          heartbeatData = payload;
        } else {
          throw new Error("Invalid payload type");
        }

        console.log("Parsed heartbeat data:", heartbeatData);

        if (heartbeatData && Array.isArray(heartbeatData.robots)) {
          handleHeartbeat(heartbeatData.robots);
        } else if (heartbeatData && heartbeatData.result) {
          // Handle single robot heartbeat
          handleHeartbeat([heartbeatData.result]);
        } else {
          console.error("Invalid heartbeat data format:", heartbeatData);
        }
      } catch (error) {
        console.error("Error processing heartbeat message:", error);
        console.error("Problematic payload:", payload);
      }
    } else if (regex.test(topic)) {
      const robotId = extractRobotIdFromTopic(topic);

      if (robotId) {
        if (
          payload.result &&
          payload.command == "capture-image" &&
          typeof robotId === "string"
        ) {
          const robotIndex = robots.findIndex((robot) => robot.id === robotId);
          if (robotIndex !== -1) {
            robots[
              robotIndex
            ].image_data = `data:image/png;base64,${payload.result.image}`;
          }
        } else if (
          payload.result &&
          payload.command == "status" &&
          typeof robotId === "string"
        ) {
          const parsedData = payload.result;
          const robotId = extractRobotIdFromTopic(topic);

          const robotIndex = robots.findIndex((r) => r.id === robotId);
          if (robotIndex !== -1) {
            const oldState = { ...robots[robotIndex] }; // Cloning the old state
            updateHeartbeat(robotId);

            // Update the robot's state only if parsedData.state is valid
            if (parsedData.state !== undefined && parsedData.state !== null) {
              robots[robotIndex].state = parsedData.state;
            }

            // Update other properties
            robots[robotIndex].totalDistance = parsedData.totalDistance;
            robots[robotIndex].distance = parsedData.distance;
            robots[robotIndex].objects = parsedData.objects;

            // Update the canvas with new detected objects if they exist
            if (parsedData.objects) {
              updateCanvasWithDetectedObjects(robotId, parsedData.objects);
            }

            // Update the robotResponseTracker
            const trackedResponse = robotResponseTracker.get(robotId);
            if (trackedResponse && !trackedResponse.resolved) {
              robotResponseTracker.set(robotId, { resolved: true, data: payload.result });
            }
          }
        } else {
          console.log("NO TOPIC match.");
        }
      } else {
        console.log("robot id couldn't be extracted.");
      }
    }
  } catch (e) {
    console.error("Error processing message:", e, JSON.stringify(e, null, 2));
    return;
  }
};

function updateRobotStatus(robotId, status) {
  const robotIndex = robots.findIndex((r) => r.id === robotId);
  console.log("updateRobotStatus", robotId, robotIndex);
  if (robotIndex !== -1) {
    robots[robotIndex] = {
      ...robots[robotIndex],
      state: status.state,
      distance: status.distance,
      totalDistance: status.totalDistance,
      objects: status.objects || [], // Ensure objects is always an array
    };
    return true;
  } else {
    return false;
  }
}

// Add this new function to update the canvas
function updateCanvasWithDetectedObjects(robotId, objects) {
  if (!isTestMode.value) {
    console.log("Not in simulator mode, skipping canvas update");
    return;
  }

  const canvas = document.getElementById(`canvas-${robotId}`);
  if (!canvas) {
    console.error(`Canvas for robot ${robotId} not found`);
    closeAllSimulatorWindows();
    return;
  }

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  objects.forEach((obj) => {
    const x = obj.x * canvas.width;
    const y = obj.y * canvas.height;
    const width = obj.width * canvas.width;
    const height = obj.height * canvas.height;

    // Determine if the object is a robot or not
    const isRobot = obj.type === "robot";

    // Set colors based on object type
    ctx.strokeStyle = isRobot ? "rgb(255, 20, 147)" : "cyan"; // Bright pink for robots, cyan for other objects
    ctx.lineWidth = 2;
    ctx.strokeRect(x - width / 2, y - height / 2, width, height);

    // Draw center point
    const centerX = x;
    const centerY = y;
    const radius = 5; // 5px radius for center point

    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.fillStyle = isRobot ? "rgb(255, 105, 180)" : "red"; // Hot pink fill for robots, red for other objects
    ctx.fill();
    ctx.strokeStyle = "yellow";
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.closePath();

    // Add label
    ctx.fillStyle = isRobot ? "rgb(255, 20, 147)" : "cyan";
    ctx.font = "12px Arial";
    ctx.fillText(obj.type || "Object", x - width / 2, y - height / 2 - 5);
  });
}

function onConnectionLost(responseObject) {
  if (responseObject.errorCode !== 0) {
    console.error("Connection lost. Error code:", responseObject.errorCode);
    console.error("Error message:", responseObject.errorMessage);
    handleGlobalError(new Error(responseObject.errorMessage));
    isConnected.value = false;
    connectionStatus.value = "disconnected";
    addLog("error", `Connection lost: ${responseObject.errorMessage}`);

    if (isOnline.value && !isConnecting.value && retryAttempts < MAX_RETRY_ATTEMPTS) {
      console.log(
        `Attempting to reconnect... (Attempt ${
          retryAttempts + 1
        } of ${MAX_RETRY_ATTEMPTS})`
      );
      connectionStatus.value = "reconnecting";
      retryAttempts++;
      connectWithRetry(MAX_RETRY_ATTEMPTS - retryAttempts);
    } else if (retryAttempts >= MAX_RETRY_ATTEMPTS) {
      console.log("Max retry attempts reached. Please try reconnecting manually.");
      addLog("error", "Max retry attempts reached. Please try reconnecting manually.");
    } else {
      console.log(
        "Device is offline or connection attempt already in progress. Will attempt to reconnect when online and not connecting."
      );
    }
  }
}

function onConnect() {
  console.log("MQTT Connected");
  isConnected.value = true;
  connectionStatus.value = "connected";
  addLog("notice", "MQTT Connected");
  clearInterval(reconnectInterval.value);
  reconnectInterval.value = null;
  retryAttempts = 0; // Reset retry attempts

  subscribeToTopics();
  sendCommand("ping");
}

function subscribeToTopics() {
  if (!ensureConnected()) return;

  const topicsToSubscribe = [
    `${mainTopic.value}/logs`,
    `${mainTopic.value}/response/swarm`,
    `${mainTopic.value}/heartbeat`,
  ];

  topicsToSubscribe.forEach((topic) => {
    if (client.value && client.value.isConnected()) {
      addLog("subscribe", "Subscribe", topic, "");
      client.value.subscribe(topic);
    } else {
      console.error(`Failed to subscribe to ${topic}: MQTT client not connected`);
    }
  });
}

function onFail(responseObject) {
  console.error("Failed to connect. Error code:", responseObject.errorCode);
  console.error("Error message:", responseObject.errorMessage);
  connectionStatus.value = "disconnected";
  addLog("error", `Connection failed: ${responseObject.errorMessage}`);
}

function ensureConnected() {
  if (!client.value || !client.value.isConnected()) {
    if (retryAttempts < MAX_RETRY_ATTEMPTS) {
      retryAttempts = retryAttempts + 1;
    }
    console.error(
      "MQTT client is not connected. Attempting to reconnect...",
      retryAttempts
    );
    connectWithRetry(MAX_RETRY_ATTEMPTS - retryAttempts);
    return false;
  }
  return true;
}

function getIcon(type) {
  switch (type) {
    case "error":
      return "fa-exclamation-circle";
    case "subscribe":
      return "fa-info-circle";
    case "unsubscribe":
      return "fa-info-circle";
    case "notice":
      return "fa-info-circle";
    case "send":
      return "fa-paper-plane";
    case "receive":
      return "fa-inbox";
    default:
      return "fa-circle";
  }
}

const addLog = (type, message, topic = "", payload = "") => {
  const log = {
    id: logId.value++,
    type,
    message,
    topic,
    payload,
    timestamp: new Date().toLocaleTimeString(),
    testMode: isTestMode.value,
  };
  logs.value.push(log);
  trimLogs();
  emit("log-added", log);
  emit("log-updated", logs.value);

  // Schedule scrolling after the DOM has updated
  nextTick(() => {
    if (!userScrolled.value) {
      scrollToBottom();
    }
  });
};

const trimLogs = () => {
  if (logs.value.length > MAX_LOG_ENTRIES) {
    logs.value = logs.value.slice(-MAX_LOG_ENTRIES);
  }
};

const displayedLogs = computed(() => {
  return logs.value.slice(-MAX_LOG_ENTRIES);
});

const scrollToBottom = () => {
  if (logContainer.value) {
    logContainer.value.scrollTop = logContainer.value.scrollHeight;
  }
};

const handleScroll = () => {
  if (logContainer.value) {
    const { scrollTop, scrollHeight, clientHeight } = logContainer.value;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;

    if (isAtBottom) {
      userScrolled.value = false;
    } else if (!userScrolled.value) {
      userScrolled.value = true;
    }
  }
};

function sendCommand(command) {
  console.log(`sendCommand called with: ${command}`);
  if (!ensureConnected()) return;

  if (command === "ping") {
    if (currentExperiment?.value?.swarm_id) {
      sendSwarmCommand(currentExperiment.value.swarm_id, "ping");
    } else {
      userRobots.value.forEach((robot) => {
        if (robot.id) {
          sendControlCommand(robot.id, "ping");
        }
      });
    }
    return;
  }

  if (robots.length === 0) {
    console.warn("No connected robots found");
    return;
  }

  if (command === "disperse") {
    isDispersing.value = true;
    isGathering.value = false;
  } else if (command === "gather") {
    isGathering.value = true;
    isDispersing.value = false;
  } else if (command === "stop") {
    isGathering.value = false;
    isDispersing.value = false;
  }

  if (command === "disperse" || command === "gather") {
    isCommandLooping.value = true;
    sendRandomCommands(command);
  } else if (command === "stop") {
    isCommandLooping.value = false;
    if (commandInterval) {
      clearInterval(commandInterval);
      commandInterval = null;
    }
    sendStopCommand();
  } else {
    robots.forEach((robot) => {
      if (robot.id) {
        sendControlCommand(robot.id, command);
      }
    });
  }
}

const stopAllProcesses = async () => {
  if (
    isTraining.value ||
    isTrainingOrRetraining.value ||
    isGathering.value ||
    isDispersing.value
  ) {
    console.log("Stopping all active processes");
    isCommandLooping.value = false;
    if (commandInterval) {
      clearInterval(commandInterval);
      commandInterval = null;
    }
    await sendStopCommand();

    if (currentExperiment.value) {
      const updatedExperiment = await updateExperiment(currentExperiment.value._id, {
        status: "Stopped",
      });
      emit("experiment-updated", updatedExperiment);
      emit("training-stopped", updatedExperiment);
      currentExperiment.value = null;
    }

    isTraining.value = false;
    isTrainingOrRetraining.value = false;
    isGathering.value = false;
    isDispersing.value = false;
  }
};

function sendRandomCommands(mode) {
  console.log(`sendRandomCommands called with mode: ${mode}`);
  const commands = ["move-forward", "move-backward", "turn-left", "turn-right"];

  if (commandInterval) {
    clearInterval(commandInterval);
    commandInterval = null;
  }

  let loopCount = 0;
  const maxLoops = 100; // Adjust this value as needed

  const sendCommandToRobot = (robot, index) => {
    if (robot.id) {
      const randomCommand = commands[Math.floor(Math.random() * commands.length)];
      let fullCommand;

      if (randomCommand === "stop") {
        fullCommand = randomCommand;
      } else {
        const duration =
          mode === "disperse"
            ? Math.floor(Math.random() * 500) + 100 // 1-3 seconds for disperse
            : Math.floor(Math.random() * 300) + 50; // 0.5-1.5 seconds for gather
        fullCommand = `${randomCommand}:${duration}`;
      }

      sendControlCommand(robot.id, fullCommand);

      // Schedule next command for this robot
      const nextCommandDelay =
        randomCommand === "stop" ? 500 : parseInt(fullCommand.split(":")[1]) + 50;
      setTimeout(() => {
        if (isCommandLooping.value && loopCount < maxLoops) {
          sendCommandToRobot(robot, index);
        }
      }, nextCommandDelay);
    }
  };

  // Start the command loop for each robot
  robots.forEach((robot, index) => {
    sendCommandToRobot(robot, index);
  });

  // Set an interval to check if we should stop the loop
  commandInterval = setInterval(() => {
    loopCount++;
    if (!isCommandLooping.value || loopCount >= maxLoops) {
      clearInterval(commandInterval);
      commandInterval = null;
      isCommandLooping.value = false;
      console.log("Random command loop ended");
    }
  }, 1000); // Check every second
}

const sendStopCommand = async () => {
  console.log("sendStopCommand called");
  if (robots.length === 0) return;
  robots.forEach((robot) => {
    if (robot.id) {
      sendControlCommand(robot.id, "stop");
    }
  });
};


async function sendControlCommand(robotId, command, waitForResponse = false) {
  if (!ensureConnected()) return;

  const topic = `${mainTopic.value}/command/robot/${robotId}`;
  const json_command = JSON.stringify({ command });
  const message = new Message(json_command);
  message.destinationName = topic;
  console.log(`Sending command to robot ${robotId}: ${command}`);
  addLog("send", "", topic, command);
  client.value.send(message);

  // Reset the robot's image to default after sending a movement command
  if (command.startsWith("move") || command.startsWith("turn")) {
    const robotIndex = robots.findIndex((robot) => robot.id === robotId);
    if (robotIndex !== -1) {
      robots[robotIndex].image_data = DEFAULT_ROBOT_IMAGE;
    }
  }

  if (waitForResponse) {
    return new Promise((resolve, reject) => {
      const timeoutDuration = 5000; // 5 seconds timeout

      // Set up response tracking for this robot
      robotResponseTracker.set(robotId, { resolved: false, data: null });

      const checkResponse = () => {
        const response = robotResponseTracker.get(robotId);
        if (response && response.resolved) {
          cleanup();
          resolve(response.data);
        }
      };

      const cleanup = () => {
        clearTimeout(timeoutId);
        clearInterval(checkIntervalId);
        robotResponseTracker.delete(robotId);
      };

      const onTimeout = () => {
        cleanup();
        reject(new Error(`Timeout waiting for status from robot ${robotId}`));
      };

      const timeoutId = setTimeout(onTimeout, timeoutDuration);
      const checkIntervalId = setInterval(checkResponse, 100); // Check every 100ms
    });
  }
}

function handleRobotDisconnect(robotId) {
  console.log(`Handling disconnect for robot: ${robotId}`);
  const index = robots.findIndex(
    (robot) =>
      robot.robot_id === robotId ||
      robot.id === robotId ||
      robot.mac_address === robotId ||
      robot.id === robotId.toLowerCase() ||
      robot.mac_address === robotId.toLowerCase()
  );

  if (index !== -1) {
    const robot = robots[index];
    stopRobotProcesses(robot);
    client.value.unsubscribe(`${mainTopic.value}/response/robot/${robot.id}`);

    if (isTraining.value || isTrainingOrRetraining.value) {
      console.log(`Robot ${robot.id} disconnected during training. Pausing training.`);
      disconnectedRobots.value.add(robot.id);
      pauseTraining();
    } else {
      robots.splice(index, 1);
    }

    console.log(`Robot ${robot.id} (MAC: ${robot.mac_address}) handled for disconnect`);
  } else {
    console.log(`Robot with ID/MAC ${robotId} not found in the list`);
  }

  if (robotIntervals[robotId]) {
    if (robotIntervals[robotId].heartbeatTimer) {
      clearTimeout(robotIntervals[robotId].heartbeatTimer);
    }
    delete robotIntervals[robotId];
    console.log(`Cleared intervals for robot ${robotId}`);
  }
}

function handleRobotReconnect(robotId) {
  console.log(`Handling reconnect for robot: ${robotId}`);
  disconnectedRobots.value.delete(robotId);

  if (
    disconnectedRobots.value.size === 0 &&
    (isTraining.value || isTrainingOrRetraining.value)
  ) {
    console.log("All robots reconnected. Resuming training.");
    resumeTraining();
  } 
}

function stopRobotProcesses(robot) {
  // Stop gathering process
  if (isGathering.value) {
    console.log(`Stopping gathering process for robot ${robot.id}`);
    sendControlCommand(robot.id, "stop");
  }

  // Stop dispersing process
  if (isDispersing.value) {
    console.log(`Stopping dispersing process for robot ${robot.id}`);
    sendControlCommand(robot.id, "stop");
  }

  // Stop any ongoing command loop for this robot
  if (isCommandLooping.value) {
    console.log(`Stopping command loop for robot ${robot.id}`);
    isCommandLooping.value = false;
    if (commandInterval) {
      clearInterval(commandInterval);
      commandInterval = null;
    }
  }

  // If the robot is part of a training session, handle accordingly
  if (isTraining.value || isTrainingOrRetraining.value) {
    console.log(`Robot ${robot.id} disconnected during training/retraining session`);
    // You might want to add logic here to pause or stop the training session,
    // or to remove this robot from the training set
  }

  // Clear any specific intervals or timeouts for this robot
  if (robotIntervals[robot.id]) {
    Object.keys(robotIntervals[robot.id]).forEach((intervalKey) => {
      if (robotIntervals[robot.id][intervalKey]) {
        clearInterval(robotIntervals[robot.id][intervalKey]);
        clearTimeout(robotIntervals[robot.id][intervalKey]);
      }
    });
    delete robotIntervals[robot.id];
    console.log(`Cleared all intervals and timeouts for robot ${robot.id}`);
  }
}

const handleUserLogin = () => {
  console.log("User logged in, reconnecting MQTT...");
  if (client.value && client.value.isConnected()) {
    client.value.disconnect();
  }
  // Load all settings from localStorage after login
  isTestMode.value = localStorage.getItem("simulationMode") === "true";
  ensureConnected();
};

const handleUserLogout = (event) => {
  console.log("User logout event received, disconnecting MQTT...");
  const logoutSuccess = event.detail.success;

  if (setupMQTT.value) {
    if (setupMQTT.value.client.value && setupMQTT.value.isConnected.value) {
      setupMQTT.value.client.value.disconnect();
    }
    // Clear all robot data
    robots.splice(0, robots.length);
    // Clear all intervals
    Object.values(setupMQTT.value.robotIntervals).forEach((interval) => {
      if (interval.heartbeatTimer) {
        clearTimeout(interval.heartbeatTimer);
      }
    });
    setupMQTT.value.robotIntervals = {};
    // Reset connection status
    connectionStatus.value = "disconnected";
    // Clear logs if needed
    logs.value = [];
  }

  if (!logoutSuccess) {
    console.warn(
      "Logout was not successful, but MQTT connection has been terminated for security."
    );
    // You might want to add some user feedback here about the failed logout
  }
};

const handleLoginSuccess = () => {
  console.log("Login successful, connecting to MQTT...");
  connectMQTT();
};

const filteredRobots = computed(() => robots.filter((robot) => robot.id));

// Wrap all the code that depends on the user being logged in inside a computed
const setupMQTT = computed(() => {
  if (!currentUser.value) return null;

  // Return an object with all the necessary setup
  return {
    policyModel: null,
    valueModel: null,
    agent: ref(null),
    logs: ref([]),
    robots: reactive([]),
    isTestMode: ref(false),
    client: ref(null),
    statuses: ["initializing", "dispersing", "gathering", "stopped"],
    robotIntervals: reactive({}),
    logId: ref(0),
    isOnline: ref(navigator.onLine),
    reconnectInterval: ref(null),
    mainTopic: ref(isTestMode.value ? "robotswarm-sim" : "robotswarm"),
    isConnected: ref(false),
    env: ref(new MultiRobotEnvironment([])),

    // Include all the existing methods here
    saveModels,
    loadModels,
    initializeAgent,
    getRobotStatus,
    connectMQTT,
    extractRobotIdFromTopic,
    escapeRegex,
    onMessageArrived,
    onConnect,
    subscribeToTopics,
    onFail,
    onConnectionLost,
    ensureConnected,
    getIcon,
    addLog,
    sendCommand,
    sendControlCommand,
    scrollToBottom,
    handleRobotDisconnect,
    //resetRobotIntervals,
    handleUserLogin,
    handleUserLogout,

    // ... other methods ...
  };
});

// Use a watch to react to changes in the setupMQTT computed
watch(
  setupMQTT,
  (newSetup, oldSetup) => {
    if (oldSetup) {
      // Clean up the old setup
      if (
        oldSetup.client &&
        oldSetup.client.value &&
        oldSetup.client.value.isConnected()
      ) {
        oldSetup.client.value.disconnect();
      }
      // Clear any intervals, timeouts, etc.
      Object.values(oldSetup.robotIntervals).forEach((interval) => {
        if (interval.heartbeatTimer) {
          clearTimeout(interval.heartbeatTimer);
        }
      });
    }

    if (newSetup) {
      ensureConnected();
    }
  },
  { immediate: true }
);

const handleInitiateMQTTConnection = () => {
  console.log("Initiating MQTT connection...");
  connectWithRetry(MAX_RETRY_ATTEMPTS - retryAttempts);
};

const handleOnline = () => {
  isOnline.value = true;
  connectWithRetry(MAX_RETRY_ATTEMPTS - retryAttempts); // Reset retry count when coming back online
};

const handleOffline = () => {
  isOnline.value = false;
};

const selectedRobot = ref(null);
const isExperimentFormOpen = ref(false);

const selectRobot = (robotId) => {
  selectedRobot.value = robotId;
};

const pressedKeys = new Set();

const handleKeyDown = (event) => {
  if (isExperimentFormOpen.value) return;

  if (
    ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", " ", "Enter", "Tab"].includes(
      event.key
    )
  ) {
    event.preventDefault();
    pressedKeys.add(event.key);
    updateRobotMovement();
  }

  if (event.key === "Tab") {
    event.preventDefault();
    handleTabNavigation(event.shiftKey);
  }
};

const handleKeyUp = (event) => {
  if (isExperimentFormOpen.value) return;

  if (
    ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", " ", "Enter", "Tab"].includes(
      event.key
    )
  ) {
    pressedKeys.delete(event.key);
    if (pressedKeys.size === 0) {
      sendControlCommand(selectedRobot.value, "stop");
    } else {
      updateRobotMovement();
    }
  }
};

const updateRobotMovement = () => {
  if (!selectedRobot.value) return;

  const forward = pressedKeys.has("ArrowUp");
  const backward = pressedKeys.has("ArrowDown");
  const left = pressedKeys.has("ArrowLeft");
  const right = pressedKeys.has("ArrowRight");

  if (forward && left) {
    sendControlCommand(selectedRobot.value, "turn-left:500");
  } else if (forward && right) {
    sendControlCommand(selectedRobot.value, "turn-right:500");
  } else if (backward && left) {
    sendControlCommand(selectedRobot.value, "turn-right:500");
  } else if (backward && right) {
    sendControlCommand(selectedRobot.value, "turn-left:500");
  } else if (forward) {
    sendControlCommand(selectedRobot.value, "move-forward:500");
  } else if (backward) {
    sendControlCommand(selectedRobot.value, "move-backward:500");
  } else if (left) {
    sendControlCommand(selectedRobot.value, "turn-left:500");
  } else if (right) {
    sendControlCommand(selectedRobot.value, "turn-right:500");
  }

  if (pressedKeys.has(" ")) {
    sendControlCommand(selectedRobot.value, "stop");
  }
  if (pressedKeys.has("Enter")) {
    sendControlCommand(selectedRobot.value, "capture-image");
  }
};

const handleTabNavigation = (isShiftPressed) => {
  const robotIds = filteredRobots.value.map((robot) => robot.id);
  if (robotIds.length === 0) return;

  if (!selectedRobot.value) {
    selectedRobot.value = isShiftPressed ? robotIds[robotIds.length - 1] : robotIds[0];
    return;
  }

  const currentIndex = robotIds.indexOf(selectedRobot.value);
  let nextIndex;

  if (isShiftPressed) {
    nextIndex = currentIndex > 0 ? currentIndex - 1 : robotIds.length - 1;
  } else {
    nextIndex = currentIndex < robotIds.length - 1 ? currentIndex + 1 : 0;
  }

  selectedRobot.value = robotIds[nextIndex];
};

const handleExperimentFormOpened = () => {
  console.log("RobotSwarmController: handleExperimentFormOpened called");
  isExperimentFormOpen.value = true;
  toggleKeyboardCaptures(false);
};

const handleExperimentFormClosed = () => {
  console.log("RobotSwarmController: handleExperimentFormClosed called");
  isExperimentFormOpen.value = false;
  toggleKeyboardCaptures(true);
};

const toggleKeyboardCaptures = (enable) => {
  if (enable) {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
  } else {
    document.removeEventListener("keydown", handleKeyDown);
    document.removeEventListener("keyup", handleKeyUp);
  }
};

onMounted(async () => {
  try {
    console.log("TensorFlow.js initialized with backend:", tf.getBackend());
    setupHeartbeat();
    setInterval(checkInactiveRobots, HEARTBEAT_INTERVAL);
  } catch (error) {
    console.error("Error during component mount:", error);
    // Handle the error appropriately
  }

  // Load simulation mode from localStorage and update mainTopic
  isTestMode.value = localStorage.getItem("simulationMode") === "true";
  mainTopic.value = isTestMode.value ? "robotswarm-sim" : "robotswarm";
  console.log(
    `Initial mode: ${isTestMode.value ? "simulation" : "production"}. Main topic is '${
      mainTopic.value
    }'.`
  );

  setupCacheCleanup();
  inactiveCheckInterval = setInterval(checkInactiveRobots, 5000); // Check every 5 seconds

  window.addEventListener("online", handleOnline);
  window.addEventListener("offline", handleOffline);
  window.addEventListener("login-successful", handleLoginSuccess);
  window.addEventListener("initiate-mqtt-connection", handleInitiateMQTTConnection);
  window.addEventListener("user-login", handleUserLogin);
  window.addEventListener("user-logout", handleUserLogout);
  window.addEventListener("start-experiment-training", startExperimentTraining);
  window.addEventListener("keydown", handleKeyDown);
  window.addEventListener("keyup", handleKeyUp);
  window.addEventListener("experiment-form-opened", handleExperimentFormOpened);
  window.addEventListener("experiment-form-closed", handleExperimentFormClosed);
  window.addEventListener("beforeunload", stopAllProcesses);
  router.beforeEach(async (to, from, next) => {
    await stopAllProcesses();
    next();
  });

  // Check if user is already logged in and connect MQTT if so
  if (await AuthService.getCurrentUser()) {
    handleInitiateMQTTConnection();
  }

  fetchUserRobots();

  if (logContainer.value) {
    logContainer.value.addEventListener("scroll", handleScroll);
  }
});

onBeforeUnmount(async() => {
  if (setupMQTT.value) {
    if (setupMQTT.value.reconnectInterval.value) {
      clearInterval(setupMQTT.value.reconnectInterval.value);
    }
    if (
      setupMQTT.value &&
      setupMQTT.value.client.value &&
      setupMQTT.value.isConnected.value
    ) {
      setupMQTT.value.client.value.disconnect();
    }
    Object.values(setupMQTT.value.robotIntervals).forEach((interval) => {
      if (interval.heartbeatTimer) {
        clearTimeout(interval.heartbeatTimer);
      }
    });
    if (inactiveCheckInterval) {
      clearInterval(inactiveCheckInterval);
    }
    if (cacheCleanupInterval) {
      clearInterval(cacheCleanupInterval);
    }
    if (statusTimeout) {
      clearTimeout(statusTimeout);
    }
    if (commandInterval) {
      clearInterval(commandInterval);
    }

    window.removeEventListener("online", handleOnline);
    window.removeEventListener("offline", handleOffline);
    window.removeEventListener("login-successful", handleLoginSuccess);
    window.removeEventListener("initiate-mqtt-connection", handleInitiateMQTTConnection);
    window.removeEventListener("user-login", handleUserLogin);
    window.removeEventListener("user-logout", handleUserLogout);
    window.removeEventListener("start-experiment-training", startExperimentTraining);
    window.removeEventListener("keydown", handleKeyDown);
    window.removeEventListener("keyup", handleKeyUp);
    window.removeEventListener("experiment-form-opened", handleExperimentFormOpened);
    window.removeEventListener("experiment-form-closed", handleExperimentFormClosed);
    window.removeEventListener("beforeunload", stopAllProcesses);
  }

  if (inactiveCheckInterval) {
    clearInterval(inactiveCheckInterval);
  }

  window.removeEventListener("online", handleOnline);
  window.removeEventListener("offline", handleOffline);
  window.removeEventListener("login-successful", handleLoginSuccess);
  window.removeEventListener("initiate-mqtt-connection", handleInitiateMQTTConnection);
  window.removeEventListener("user-login", handleUserLogin);
  window.removeEventListener("user-logout", handleUserLogout);
  window.removeEventListener("start-experiment-training", startExperimentTraining);
  window.removeEventListener("keydown", handleKeyDown);
  window.removeEventListener("keyup", handleKeyUp);
  window.removeEventListener("experiment-form-opened", handleExperimentFormOpened);
  window.removeEventListener("experiment-form-closed", handleExperimentFormClosed);
  window.removeEventListener("beforeunload", stopAllProcesses);

  // Close the simulator window if it's still open
  closeAllSimulatorWindows();

  await cleanupTFMemory();

  if (logContainer.value) {
    logContainer.value.removeEventListener("scroll", handleScroll);
  }
});

// Expose necessary properties and methods
const exposedProperties = computed(() => {
  return {
    logs,
    robots,
    isTestMode,
    connectMQTT: setupMQTT.value ? setupMQTT.value.connectMQTT : null,
    isTfReady,
  };
});

const currentExperiment = ref(null);

const showExperimentForm = ref(false);

const robotSwarmControllerData = computed(() => ({
  isTestMode: isTestMode.value,
  numRobots: isTestMode.value ? 3 : robots.length,
  robots: robots,
}));

const stopExperimentTraining = async (experiment) => {
  console.log("RobotSwarmController: stopExperimentTraining called", experiment);
  if (!experiment || !experiment._id) {
    console.error("Invalid experiment object:", experiment);
    throw new Error("Invalid experiment data");
  }

  try {
    let experimentToStop = experiment;
    if (currentExperiment.value && currentExperiment.value._id === experiment._id) {
      experimentToStop = currentExperiment.value;
    }

    console.log("RobotSwarmController: Stopping experiment: %s", experimentToStop._id);

    // Update the experiment status only if it's not already completed
    if (experimentToStop.status !== "Completed") {
      const updatedExperiment = await updateExperiment(experimentToStop._id, {
        status:
          experimentToStop.currentEpisode == experimentToStop.numEpisodes
            ? "Completed"
            : "Stopped",
        currentEpisode: experimentToStop.currentEpisode,
        progress: experimentToStop.progress,
      });
      console.log("Updated experiment:", updatedExperiment);

      // Emit the updated experiment data
      emit("experiment-updated", updatedExperiment);
      emit("training-stopped", updatedExperiment);
      if (updatedExperiment.status === "Completed") {
        emit("show-notification", "Experiment completed successfully!");
      } else {
        emit("show-notification", "Experiment stopped successfully!");
      }

      // Only set currentExperiment to null after all operations are complete
      if (
        currentExperiment.value &&
        currentExperiment.value._id === updatedExperiment._id
      ) {
        currentExperiment.value = null;
      }
    } else {
      console.log("Experiment already completed, status not changed");
    }

    // Set a flag to stop the training loop
    isTraining.value = false;
    isTrainingOrRetraining.value = false;

    if (currentExperiment.value) {
      console.log("Experiment current status:", currentExperiment.value.status);

      if (["Running", "Paused"].includes(currentExperiment.value.status)) {
        console.log("Stopping current experiment due to simulator unload");
        try {
          await stopExperimentTraining(currentExperiment.value);
        } catch (error) {
          console.error("Error stopping experiment:", error);
        }
      } else if (currentExperiment.value.status === "Completed") {
        console.log("Experiment already completed, no need to stop");
      } else {
        console.log("Experiment in unexpected state:", currentExperiment.value.status);
      }
    } else {
      console.log("No current experiment to stop");
    }

    if (experimentToStop.isSimulation) {
      // Send MQTT command to stop the experiment
      sendExperimentCommand(experimentToStop._id, "stop");

      // Send a stop command to the simulator
      if (experimentToStop.swarm_id) {
        sendCommand("stop_simulation");
      } else {
        console.error("No swarm_id found for the experiment");
      }

      // Remove all robots in simulation mode
      robots.splice(0, robots.length);
      console.log("All simulated robots removed");
    } else {
      // Send a stop command to all swarms
      sendCommand("stop");
    }

    return experimentToStop;
  } catch (error) {
    console.error("Failed to update experiment status:", error);
    emit("show-notification", "Failed to update experiment status.");
    throw error;
  }
};

const selectBestPerformingMultiRobotPPO = async (multiRobotPPOs) => {
  try {
    let bestPerformingPPO = null;
    let bestPerformingPPOId = null;

    for (const [ppoId, ppo] of Object.entries(multiRobotPPOs.value)) {
      console.log(`PPO ${ppoId} average rewards:`, ppo.averageRewards);
      if (!bestPerformingPPO || ppo.averageRewards > bestPerformingPPO.averageRewards) {
        bestPerformingPPO = ppo;
        bestPerformingPPOId = ppoId;
      }
    }

    console.log("Best performing PPO ID:", bestPerformingPPOId);
    console.log("Best performing PPO average rewards:", bestPerformingPPO.averageRewards);

    // Ensure that the selected PPO has valid actor and critic models
    if (
      bestPerformingPPO &&
      bestPerformingPPO.actor &&
      typeof bestPerformingPPO.actor.save === "function" &&
      bestPerformingPPO.critic &&
      typeof bestPerformingPPO.critic.save === "function"
    ) {
      console.log("Best performing PPO has valid actor and critic models");
      return bestPerformingPPO;
    } else {
      console.error("Best performing PPO does not have valid actor or critic models");
      return null;
    }
  } catch (error) {
    console.error("Failed to select best performing PPO:", error);
    return null;
  }
};

const pauseTraining = () => {
  console.log("Pausing training due to robot disconnection");
  // Add any necessary logic to pause the training process
  // This might involve stopping certain intervals or timers
};

const resumeTraining = () => {
  console.log("Resuming training after robot reconnection");
  // Add logic to resume the training process
  // This might involve restarting intervals or timers
};

async function cleanupTFMemory() {
  try {
    await tf.ready();
    //tf.disposeVariables();
    
    const backend = tf.getBackend();
    console.log(`Current backend: ${backend}`);

    if (backend === 'webgpu' || backend === 'webgl') {
      const gl = tf.backend().gpgpu?.gl;
      if (gl) {
        const numTexturesInGPU = gl.getParameter(gl.TEXTURE_BINDING_2D);
        if (numTexturesInGPU > 0) {
          console.warn(`There are still ${numTexturesInGPU} textures in the GPU`);
        }
        const loseContextExt = gl.getExtension('WEBGL_lose_context');
        if (loseContextExt) {
          loseContextExt.loseContext();
        } else {
          console.warn('WEBGL_lose_context extension not available');
        }
      } else {
        console.warn('WebGL context not available');
      }
    } else {
      console.log(`Backend ${backend} doesn't require special cleanup`);
    }

    console.log('TensorFlow memory cleaned up');
  } catch (error) {
    console.error('Error during TensorFlow memory cleanup:', error);
  }
}

const fetchUserRobots = async () => {
  try {
    const response = await axios.get("/api/robots");
    userRobots.value = response.data;
  } catch (error) {
    console.error("Error fetching user robots:", error);
  }
};

const checkRobotOwnership = async (macAddress, isSimulated) => {
  const cacheKey = `${macAddress}-${isSimulated}`;
  const now = Date.now();

  // Check if we have a valid cached entry
  if (ownershipCache.has(cacheKey)) {
    const cachedData = ownershipCache.get(cacheKey);
    if (now - cachedData.timestamp < CACHE_EXPIRATION_TIME) {
      return cachedData.data;
    } else {
      // Remove expired cache entry
      ownershipCache.delete(cacheKey);
    }
  }

  try {
    const response = await axios.post("/api/robots/check-ownership", {
      mac_address: macAddress,
      is_simulated: isSimulated,
    });

    // Cache the response
    ownershipCache.set(cacheKey, {
      data: response.data,
      timestamp: now,
    });

    return response.data;
  } catch (error) {
    console.error("Error checking robot ownership:", error);
    return { owned: false };
  }
};

// Function to clear the cache (you can call this periodically or when needed)
const clearOwnershipCache = () => {
  ownershipCache.clear();
  console.log("Robot ownership cache cleared");
};

// Optionally, you can set up a periodic cache cleanup
const setupCacheCleanup = () => {
  setInterval(() => {
    const now = Date.now();
    for (const [key, value] of ownershipCache.entries()) {
      if (now - value.timestamp > CACHE_EXPIRATION_TIME) {
        ownershipCache.delete(key);
      }
    }
    console.log("Cleaned up expired cache entries");
  }, CACHE_EXPIRATION_TIME);
};

defineExpose({
  ...exposedProperties.value,

  // Data
  robotSwarmControllerData,
  userRobots,
  isTraining,
  isSimulatorOpen,
  isSimulatorLoaded,
  showExperimentForm,

  // Core experiment functions
  startExperimentTraining,
  stopExperimentTraining,
  handleExperimentAdded,
  handleSimulatorUnload,
  handleExperimentFormOpened,
  handleExperimentFormClosed,

  // Simulation and mode settings
  setSimulationMode,
  openSimulator,
  closeSimulator,

  // Command functions
  sendCommand,
  sendRandomCommands,
  sendStopCommand,
  stopAllProcesses,

  // User interaction
  toggleKeyboardCaptures,

  // Data fetching and management
  getAllLogs,
  fetchUserRobots,
  checkRobotOwnership,
  clearOwnershipCache,
});
</script>

<style scoped>
.robot-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.robot-title {
  width: 100%;
}

.canvas-overlay-container {
  position: relative;
  width: 100%;
  margin-top: 0;
}

.robot-image,
.robot-canvas {
  width: 100%;
  height: 100%;
}

.robot-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: transparent;
}

.robot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  border: 3px solid #ccc;
  margin-bottom: 10px;
  padding: 0;
  transition: all 0.3s ease;
}

canvas.robot-canvas,
img.robot-image {
  margin-bottom: 10px;
  border: 1px solid #ddd;
}

.robot-details {
  width: 100%;
}

.toggle-path {
  transition: background 0.3s ease-in-out;
}

.toggle-circle {
  transition: transform 0.3s ease-in-out;
}

input:checked ~ .toggle-path {
  background-color: #4d90fe;
}

input:checked ~ .toggle-circle {
  transform: translateX(1.25rem);
}

#tfjs-vis-model,
#tfjs-vis-metrics {
  height: 300px;
  border: 1px solid #ddd;
  margin-top: 10px;
  padding: 10px;
}

.connection-status-fixed {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
  transition: opacity 0.3s ease-in-out;
}

.connection-status-fixed.connected {
  background-color: #4caf50;
  color: white;
}

.connection-status-fixed.disconnected {
  background-color: #f44336;
  color: white;
}

.connection-status-fixed.connecting,
.connection-status-fixed.reconnecting {
  background-color: #ffa500;
  color: white;
}

.selected-robot {
  border: 3px solid #4caf50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
  padding: 0; /* Adjust padding to maintain consistent size */
}

.selected-robot .robot-title {
  background-color: #4caf50;
}

.control-button:disabled,
.control-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.control-button {
  padding: 4px; /* Add padding to the buttons */
  transition: background-color 0.3s ease; /* Optional: add a hover effect */
}

.control-button:hover:not(:disabled) {
  background-color: #e0e0e0; /* Optional: change background on hover */
}
</style>
